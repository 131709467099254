.wrapper {
  position: fixed;
  right: 30px;
  top: 52px;
  z-index: 21;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  background: #fff;
  padding: 27px 48px 24px 24px;
  max-width: 481px;
  width: 100%;
}

.close {
  position: absolute;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  right: 4px;

  @media (hover: hover) {
    &:hover {
      svg path {
        fill: var(--color-primary-hover);
        fill-opacity: 1;
      }
    }
  }
}

.content {
  display: flex;
  gap: 18px;
  width: 100%;
}

.images {
  position: relative;
  flex-basis: 110px;
  flex-shrink: 0;

  img {
    width: 71px;
    height: 71px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
  }

  img:nth-of-type(2) {
    position: absolute;
    border: 1px solid white;
    top: -1px;
    left: 38px;
  }
}

.notification {
  width: 100%;
}

.title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 5px;
}

.text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 11px;
  max-width: 276px;
}

.cta {
  color: rgba(0, 0, 0, 0.87);
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 9px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button:first-of-type {
    color: rgba(0, 0, 0, 0.38);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 150%;
      height: 200%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-primary-hover);
      }
    }
  }

  button:nth-of-type(2) {
    height: 30px;
    font-size: 13px;
    width: auto;
    min-width: 152px;
  }
}

.popupWrapper {
  max-width: 480px !important;
  width: 100%;
}

@media (max-width: 650px) {
  .wrapper {
    position: initial;
    width: 100%;
    background: transparent;
    box-shadow: none;
    box-sizing: border-box;
    padding-top: 53px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 100%;
    z-index: 0;
  }

  .content {
    flex-direction: column;
    width: 100%;
  }

  .images {
    flex-basis: 0;
    display: flex;
    justify-content: center;
    width: 216px;
    height: 140px;
    margin-bottom: 20px;
    min-height: 140px;
    margin-left: auto;
    margin-right: auto;

    img {
      position: absolute;
      width: 140px;
      height: 140px;
    }

    img:first-of-type {
      top: 0;
      left: 0;
    }

    img:nth-of-type(2) {
      right: 0;
      top: 0;
      left: auto;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 12px;
  }

  .text {
    max-width: 100%;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .cta {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .buttons {
    flex-direction: column-reverse;
    width: 100%;
    gap: 20px;

    button:nth-of-type(1) {
      font-size: 13px;
      width: 100%;

      &:after {
        display: none;
      }
    }

    button:nth-of-type(2) {
      height: 36px;
      font-size: 18px;
      width: 100%;
    }
  }
}

@media (max-width: 560px) {
  .popupWrapper {
    max-width: calc(100% - 32px) !important;
  }
}
