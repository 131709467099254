.wrapper {
  position: fixed;
  z-index: 100;
  right: 24px;
  top: 57px;
}

.notification {
  width: 458px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  background-color: var(--color-neutral-4);
  transform: translateX(498px);
  border-radius: 4px;
  padding: 27px 48px 24px 24px;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

.notification > button {
  position: absolute;
  right: -1px;
  top: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.32;

  &:hover {
    opacity: 0.5;
  }
}

.image {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: var(--color-placeholder);
  flex-shrink: 0;
  margin-right: 18px;
  overflow: hidden;
}

.userCall {
  color: var(--color-text);
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 4px;
  display: block;
}

.content p {
  color: var(--color-text);
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: left;
  margin-bottom: 10px;
}

.tournamentInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  & > span {
    color: var(--color-text);
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 24px;
    text-align: left;
    max-width: calc(100% - 94px);
    overflow: hidden;
    white-space: nowrap;
    padding-right: 15px;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }

  .participantsInfo {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);

    i {
      margin-right: 10px;
    }
  }
}

.spaceLeftInfo {
  display: flex;
  flex-direction: column;

  span {
    &:first-of-type {
      font-size: 13px;
      position: relative;
      top: -4px;
    }

    &:last-of-type {
      font-size: 9px;
    }
  }
}

.content {
  button {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    height: 30px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    right: 16px;
  }
}

@media (max-width: 560px) {
  .wrapper {
    width: calc(100% - 32px);
    left: 16px;
  }

  .notification {
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    align-items: center;
  }

  .image {
    margin-bottom: 15px;
  }
}
