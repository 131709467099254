.wrapper {
  max-width: 370px;
  box-sizing: border-box;
  padding: 48px 32px 32px;
}

.row {
  display: flex;
  gap: 17px;
  justify-content: space-between;
  margin-bottom: 25px;
}

.image {
  flex-shrink: 0;
  width: 79px;
  height: 101px;
  position: relative;

  & > div {
    position: absolute;
    width: 125px;
    height: 125px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bonus {
  display: flex;
  flex-direction: column;

  .bonusInfo {
    color: var(--text-light, rgba(0, 0, 0, 0.38));
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .bonusInfo:first-of-type {
    margin-bottom: 12px;
  }

  .bonusNumber {
    margin-bottom: 6px;
    border-radius: 14px;
    background: var(--hover-light-primary, #E6F4EA);
    display: flex;
    position: relative;
    padding: 8px 12px 8px 39px;
    gap: 3px;

    svg {
      flex-shrink: 0;
      position: absolute;
      left: 12px;
      top: 3px;
    }

    span {
      width: 41px;
      white-space: nowrap;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
      color: var(--primary, #50B772);
    }
  }

  .bonusNumberNext {
    background: rgba(253, 120, 75, 0.20);

    span {
      color: var(--secondary, #FD784B);
    }
  }
}

.title {
  color: var(--text, rgba(0, 0, 0, 0.87));
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 12px;
}

.helperText {
  color: var(--text-body, rgba(0, 0, 0, 0.60));
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;

  b {
    font-weight: 700;
  }
}

.wrapper button {
  width: auto;
  min-width: 245px;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (max-width: 420px) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    max-width: 100%;
  }

  .paper {
    max-width: calc(100% - 16px) !important;
    width: 100%;
  }

  .row {
    flex-wrap: wrap;
    gap: 8px;
  }

  .image {
    order: 0;
    flex-basis: 100%;
    width: 160px;
    height: 130px;

    & > div {
      width: 174px;
      height: 186px;
    }
  }

  .bonus {
    flex-shrink: 0;

    .bonusInfo:first-of-type {
      font-size: 11px;
      margin-bottom: 6px;

      br {
        display: none;
      }
    }

    .bonusNumber {
      padding: 4px 12px;
      display: flex;
      justify-content: center;
      gap: 6px;
      align-items: center;

      i {
        width: 24px;
        height: 24px;
      }
      svg {
        position: static;
      }
    }
  }

  .row > .bonus:first-of-type {
    order: 2;
    width: calc(50% - 4px);
  }

  .row > .bonus:last-of-type {
    order: 3;
    width: calc(50% - 4px);
  }

  .wrapper button {
    width: 100%;
  }
}
